@font-face {
  font-family: 'c64Mono';
  src: url('../public/fonts/C64_Pro_Mono-STYLE.woff2') format('woff');
}
@font-face {
  font-family: 'c64';
  src: url('../public/fonts/C64_Pro-STYLE.woff2') format('woff');
}
@font-face {
  font-family: 'Berkeley';
  src: url('../public/fonts/BerkeleyMono-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'AuthenticSans';
  src: url('../public/fonts/AUTHENTICSans-150.woff') format('woff');
}

@font-face {
  font-family: 'BasierRegular';
  src: url('../public/fonts/basiercircle-regular-webfont.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dancing+Script:wght@700&family=Kelly+Slab&family=Staatliches&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,
body {
  height: 100%;
  background-color: black;
  font-family: 'BasierRegular';
}
#loading {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  color: white;
  background: radial-gradient(#444444, #000000);
}
#loadingContent {
  font-size: 2rem;
  font-weight: 900;
  margin: auto;
  text-align: center;
}
* {
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}
